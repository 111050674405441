
import Dialog from 'primevue/dialog'
import { useStore } from 'vuex'
import { isNull } from 'lodash'
import { onMounted, ref, toRefs } from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'

export default {
  components: {
    Dialog,
  },
  props: {
    trxId: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    ritId: {
      type: String,
      default: ''
    }
  },
  emits: ['hideDialogExpenseHistory'],
  setup(props: any, { emit } : any) {
    const store = useStore()
    const isLoading = ref(false)
    const { show, trxId, ritId } = toRefs(props)
    const showModal = show.value
    const dataSource = ref([]) as any
    const dataset = ref() as any
    const TrxId = trxId.value
    const showDetailId = ref(null)
    const originExp = ref() as any
    const historyExp = ref() as any
    const endpoint = '/transaction/v1/RitExpenseRevision/History-Revision'

    const grouping = (datas: any) => {
      const datasetTemp = datas.reduce((x: any, y: any) => {
        x[y.CreatedAt] = x[y.CreatedAt] || []
        x[y.CreatedAt].push(y)
        return x
      }, Object.create(null))

      // console.log('dataSetTemp', datasetTemp)

      dataset.value = Object.keys(datasetTemp).map((key) => [key, datasetTemp[key][0].CreatedBy, datasetTemp[key]])
      console.log('cobaAfter', dataset.value)
    }

    const dateFormatting = (val: any) => {
      const newVal = moment(val.toString()).format('DD MMMM YYYY HH:mm:ss')
      const newValArr = newVal.split(' ')
      return `${newValArr[0]} ${newValArr[1]} ${newValArr[2]} at ${newValArr[3]}`
    }

    const insertOriginal = (val: any) => {
      // console.log('val', val)
      // console.log('original', originExp.value)
      let value = 0

      for (let index = 0; index < originExp.value.length; index++) {
        if (originExp.value[index].ExpenseName === val.ExpenseName) {
          // console.log('xxx')
          value = originExp.value[index].OriginalAmount
          break
        }
      }

      return value
    }

    const getAllData = async () => {
      await apiUseCase.get(`${endpoint}/${ritId.value}`).then((response) => {
        // console.log('res', response.result)
        dataSource.value = response.result
        originExp.value = dataSource.value.OriginalExpense
        const historyExpTemp = response.result.HistoryExpense
        // eslint-disable-next-line array-callback-return
        historyExpTemp.map((x: any) => {
          x.CreatedAt = moment(x.CreatedAt.toString()).format('DD MMMM YYYY')
        })
        historyExp.value = historyExpTemp.map((x: any) => ({
          ...x,
          Original: insertOriginal(x)
        }))
        // console.log('resTemp', historyExp.value)
        grouping(historyExp.value)
      })
      isLoading.value = false
      store.dispatch('hideLoading')
    }

    const dummy = ref([
      {
        id: 0,
        date: '17 Maret 2022 at 19:33:04',
        author: 'Nofrian Frisnanda',
        data: [
          {
            type: 'type 1',
            old: 1000,
            new: 2000
          },
          {
            type: 'type 2',
            old: 1000,
            new: 2000
          },
          {
            type: 'type 3',
            old: 1000,
            new: 2000
          }
        ]
      },
      {
        id: 1,
        date: '17 Maret 2022 at 20:33:04',
        author: 'Nofrian Frisnanda',
        data: [
          {
            type: 'type 1',
            old: 1000,
            new: 2000
          }
        ]
      },
      {
        id: 2,
        date: '17 Maret 2022 at 21:33:04',
        author: 'Nofrian Frisnanda',
        data: []
      },
      {
        id: 3,
        date: '17 Maret 2022 at 22:33:04',
        author: 'Nofrian Frisnanda',
        data: [
          {
            type: 'type 1',
            old: 1000,
            new: 2000
          },
        ]
      },
    ])

    const openDetail = (id: any) => {
      console.log('id', id)
      // showDetail.value = !showDetail.value
      showDetailId.value = showDetailId.value === id ? null : id
      console.log('showDetailId', showDetailId.value)
    }

    const closeDialog = () => {
      emit('hideDialogExpenseHistory')
    }

    const currencyNumberFormat = (val: any) => `Rp. ${Number(val).toLocaleString('id-ID')}`

    onMounted(() => {
      getAllData()
    })

    return {
      isLoading,
      showModal,
      dummy,
      currencyNumberFormat,
      showDetailId,
      openDetail,
      TrxId,
      closeDialog,
      dataset,
      dataSource
    }
  }
}
