
import InputNumber from 'primevue/inputnumber'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import {
  getCurrentInstance,
  onMounted,
  ref,
  toRefs
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import { isNull } from 'lodash'

export default {
  components: {
    Dialog,
    // Form,
    // Field,
    // FieldArray,
    InputNumber,
    Button
  },
  props: {
    trxId: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    ritId: {
      type: String,
      default: ''
    }
  },
  emits: ['hideDialogUpdateExpense', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const isLoading = ref(true)
    const { show, trxId, ritId } = toRefs(props)
    const showModal = show.value
    const dataSource = ref([]) as any
    const dataSourceTemp = ref([]) as any
    const dataLatestRevision = ref() as any
    const TrxId = trxId.value
    const dataForm = ref([]) as any

    const endpoint = '/transaction/v1/TransactionPp/Expense-Trx'
    const endpointExpenseRevision = '/transaction/v1/RitExpenseRevision'

    const getLatestRevision = () => {
      apiUseCase.get(`${endpointExpenseRevision}/Latest-Revision/${ritId.value}`).then((response) => {
        dataLatestRevision.value = response.result.Items
        if (dataLatestRevision.value.length > 0) {
          for (let index = 0; index < dataSourceTemp.value.length; index++) {
            const temp = {
              ritExpenseId: dataSourceTemp.value[dataSourceTemp.value.length - (index + 1)].ritExpenseId,
              type: dataSourceTemp.value[dataSourceTemp.value.length - (index + 1)].type,
              nominal: dataLatestRevision.value[index].RevisiAmount
            }
            dataSource.value.push(temp)
          }
        } else {
          dataSource.value = dataSourceTemp.value
        }
      })
    }

    const getDetail = () => {
      store.dispatch('showLoading')
      apiUseCase.get(`${endpoint}/${trxId.value}`).then((response) => {
        // dataSource.value = response.result
        const dataTemp = response.result.Rits?.filter((x: any) => x.Id === ritId.value)
        dataSourceTemp.value = dataTemp[0].RitExpenses.map((x: any) => ({
          ritExpenseId: x.Id,
          type: x.ExpenseName,
          nominal: x.Nominal,
        }))
        getLatestRevision()

        // if (dataLatestRevision.value.length > 0) {
        //   for (let index = 0; index < dataSourceTemp.value.length; index++) {
        //     const temp = {
        //       ritExpenseId: dataSourceTemp[index].value.ritExpenseId,
        //       type: dataSourceTemp[index].value.ExpenseName,
        //       nominal: dataLatestRevision[dataSourceTemp.value.length - index].RevisiAmount
        //     }
        //     dataSource.value.push(temp)
        //   }
        // } else {
        //   dataSource.value = dataSourceTemp.value
        // }
        isLoading.value = false
        store.dispatch('hideLoading')
      })
    }

    const closeDialog = () => {
      emit('hideDialogUpdateExpense')
    }

    const onSubmitConfirmation = async (val: any = '') => {
      // const deletedId = val === '' ? dataRow.value.Id : val
      const {
        error,
        message
      } = await apiUseCase.postOrPut(`${endpointExpenseRevision}`, null, dataForm.value)
      if (error) {
        $toast.add({
          severity: 'error',
          // summary: '',
          detail: message,
          group: 'bc',
          closable: false,
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          // summary: 'Berhasil melakukan Approve Setoran',
          detail: message,
          group: 'bc',
          closable: false,
          life: 3000
        })
        store.dispatch('setReloadDataList')
        emit('reloadData')
        closeDialog()
      }
      store.dispatch('hideLoading')
    }

    const onSubmit = () => {
      store.dispatch('showLoading')
      // eslint-disable-next-line array-callback-return
      dataSource.value.map((x: any) => {
        if (isNull(x.nominal)) {
          x.nominal = 0
        }
        const formTemp = {
          ritExpenseId: x.ritExpenseId,
          ritId: ritId.value,
          nominal: x.nominal,
          verbatim: null
        }
        dataForm.value.push(formTemp)
      })

      onSubmitConfirmation()
    }

    onMounted(() => {
      getDetail()
    })

    return {
      isLoading,
      showModal,
      closeDialog,
      dataSource,
      onSubmit,
      TrxId
    }
  }
}
